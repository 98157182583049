<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>    
    <p v-if="review.current_state === 'not_started'">This Review has not yet been started. Details of the review will be displayed once underway.</p>
    <div v-else>
      <template>
        <section>
          <b-table :data="review.reviewables">
            <template slot-scope="props">
              <b-table-column field="title" label="Title" sortable>
                <b-tooltip :label="props.row.title"
                    position="is-bottom">
                    {{ props.row.short_title }}
                </b-tooltip>
              </b-table-column>   

              <b-table-column field="document_type" label="Document Type" centered sortable>
                <span class='tag' v-bind:class=props.row.document_type_class>
                  {{ props.row.document_type }}
                </span>
              </b-table-column>            

              <b-table-column 
                v-for="(question, index) in filteredQuestions"
                :key="index"
                :field="'avg_score_per_question_' + index" 
                :label="question.label"
                numeric 
                sortable
                :custom-sort="(a, b, isAsc) => {
                  const result = Number(a.avg_score_per_question[index]) - Number(b.avg_score_per_question[index]);
                  return isAsc ? result : -result;
                }">
                  {{ props.row.avg_score_per_question[index] }}             
              </b-table-column>                     

              <b-table-column 
                field="total_score"
                label="Score" 
                numeric 
                sortable
                :custom-sort="(a, b, isAsc) => {
                  const result = Number(a.total_score) - Number(b.total_score);
                  return isAsc ? result : -result;
                }">
                  {{ props.row.total_score }}
              </b-table-column>   

              <b-table-column field="rank" label="Rank" numeric sortable>
                {{ props.row.rank }}
              </b-table-column>       
                                                                                                                   
            </template>
          </b-table>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ScoreRankTab",
    props: {
      id: String
    },
    mounted () {
      this.getReview()
    },
    data: function() {
      return {
        review: {},
        isLoading: true
      };
    },
    computed: {
      filteredQuestions () {
        return this.review.questions.filter(element => element.question_type !== 'comments')
      }
    },
    methods: {
      getReview() {
        var url = `/reviews/${this.id}`

        this.$secured.get(url)
          .then(response => {
            this.review = response.data

            // sort the reviewables
            this.review.reviewables = this.review.reviewables.sort((a, b) => parseFloat(b.total_score) - parseFloat(a.total_score))
            this.isLoading = false
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Failed to get review: ${error}`,
              position: 'is-bottom',
              type: 'is-danger'
            })    
          }) 
      }
    }
  }
</script>