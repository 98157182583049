var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_vm._v(" "),(_vm.review.current_state === 'not_started')?_c('p',[_vm._v("This Review has not yet been started. Details of the review will be displayed once underway.")]):_c('div',[[_c('section',[_c('b-table',{attrs:{"data":_vm.review.reviewables},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"title","label":"Title","sortable":""}},[_c('b-tooltip',{attrs:{"label":props.row.title,"position":"is-bottom"}},[_vm._v("\n                  "+_vm._s(props.row.short_title)+"\n              ")])],1),_vm._v(" "),_c('b-table-column',{attrs:{"field":"document_type","label":"Document Type","centered":"","sortable":""}},[_c('span',{staticClass:"tag",class:props.row.document_type_class},[_vm._v("\n                "+_vm._s(props.row.document_type)+"\n              ")])]),_vm._v(" "),_vm._l((_vm.filteredQuestions),function(question,index){return _c('b-table-column',{key:index,attrs:{"field":'avg_score_per_question_' + index,"label":question.label,"numeric":"","sortable":"","custom-sort":(a, b, isAsc) => {
                const result = Number(a.avg_score_per_question[index]) - Number(b.avg_score_per_question[index]);
                return isAsc ? result : -result;
              }}},[_vm._v("\n                "+_vm._s(props.row.avg_score_per_question[index])+"             \n            ")])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"total_score","label":"Score","numeric":"","sortable":"","custom-sort":(a, b, isAsc) => {
                const result = Number(a.total_score) - Number(b.total_score);
                return isAsc ? result : -result;
              }}},[_vm._v("\n                "+_vm._s(props.row.total_score)+"\n            ")]),_vm._v(" "),_c('b-table-column',{attrs:{"field":"rank","label":"Rank","numeric":"","sortable":""}},[_vm._v("\n              "+_vm._s(props.row.rank)+"\n            ")])]}}])})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }