<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>

    <form v-if="review != null" @submit.prevent="processForm()">
      <div class='columns'>
        <div class='column is-one-quarter'>
          <b-field label="Review Type">
            <b-select v-if="isNew()" v-model="review.type" expanded>
              <option v-for="reviewType in reviewTypes" :key="reviewType.type" :value="reviewType.type">{{reviewType.label}}</option>
            </b-select>
            <p v-else>{{review.friendly_type}}</p>
          </b-field>
        </div>

        <div class='column is-one-quarter'>
          <b-field label="Label" :type="fieldType('label')" :message="fieldErrors('label')">
            <b-input v-model="review.label" />
          </b-field>
        </div>

        <div class='column is-one-quarter'>
          <b-field label="Deadline" :type="fieldType('deadline_at')" :message="fieldErrors('deadline_at')">
            <b-datepicker v-model="review.deadline_at"
              placeholder="Click to select..."
              :date-formatter="dateFormatter"
              icon="calendar-alt">
            </b-datepicker>
          </b-field>
        </div>

        <div class='column is-one-quarter'>
          <b-field v-if="isAnnualReview" label="Authoring Group(s)">
            <b-select v-if="isNew()" v-model="authoringGroupId" expanded>
              <option v-for="authoringGroup in authoringGroups" :key="authoringGroup.id" :value="authoringGroup.id">{{authoringGroup.name}}</option>
            </b-select>
            <ul v-else>
              <li v-for="group in review.groups">{{group.name}}</li>
            </ul>
          </b-field>
        </div>        
      </div>

      <b-field :type="fieldType('base')" :message="fieldErrors('base')">
        <div v-if="isNew()">
          <label class="label">Reviewable Documents</label>
          <p v-if="filteredEligibleReviewables.length == 0">There are no reviewable documents available for this type of review.</p>
          <table v-else class="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th />
                <th>Document</th>
                <th style='text-align:center'>Document Type</th>
                <th v-if="isIntent" style='text-align:center'>Revision</th>
                <th v-if="isAnnualReview">Authoring Group(s)</th>
                <th v-if="isAnnualReview" style='text-align:center'>Review Interval</th>
                <th v-if="isAnnualReview" style='text-align:center'>Published At</th>
                <th v-if="isAnnualReview" style='text-align:center'>Intent in Progress</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="eligibleReviewable in filteredEligibleReviewables" :key="eligibleReviewable.id" :value="eligibleReviewable.id">
                <td>
                  <b-checkbox v-if="isMultipleSelection && eligibleReviewable.revision_intent == undefined" v-model="reviewableIds" :value="eligibleReviewable.id" :native-value="eligibleReviewable.id"></b-checkbox>
                  <b-radio v-if="isSingleSelection" v-model="reviewableIds" :value="eligibleReviewable.id" :native-value="eligibleReviewable.id"></b-radio>
                </td>
                <td>{{eligibleReviewable.title}}</td>
                <td style='text-align:center'>
                  <span :class="documentTypeClass(eligibleReviewable.document_type)">{{eligibleReviewable.document_type}}</span>
                </td>
                <td v-if="isIntent" style='text-align:center'>
                  <span class='tag' type="is-default">{{eligibleReviewable.document_subtype}}</span>
                </td>
                <td v-if="isAnnualReview">
                  <ul class="block">
                    <li v-for="authoringGroup in eligibleReviewable.authoring_groups" :key="authoringGroup.id">{{authoringGroup.name}}</li>
                  </ul>
                </td>           
                <td v-if="isAnnualReview" style='text-align:center'>{{eligibleReviewable.review_interval}}</td>            
                <td v-if="isAnnualReview" style='text-align:center'>{{eligibleReviewable.published_at}}</td>
                <td v-if="isAnnualReview" style='text-align:center'>
                  <b-button v-if="eligibleReviewable.revision_intent" type="is-link" size="is-small" icon-left="link" tag="a" :href="eligibleReviewable.revision_intent.resource_url" target="_blank"></b-button>
                </td>        
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isEdit()">
          <div v-if="isNotStarted || isUnderway">
            <label class="label">Review Documents</label>
            <table class="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Document</th>
                  <th style='text-align:center'>Document Type</th>
                  <th v-if="isIntent" style='text-align:center'>Revision</th>
                  <th v-if="isAnnualReview" style='text-align:center'>Review Interval</th>
                  <th v-if="isAnnualReview" style='text-align:center; width: 15%'>Published At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="reviewable in review.reviewables" :key="reviewable.id" :value="reviewable.id">
                  <td>
                    <div class="block">{{reviewable.title}}</div>
                    <b-input v-model="reviewable.staff_comment" placeholder="Staff Comment" size="is-small" />
                  </td>
                  <td style='text-align:center'>
                    <span :class="documentTypeClass(reviewable.document_type)">{{reviewable.document_type}}</span>
                  </td>
                  <td v-if="isIntent" style='text-align:center'>
                    <span class='tag' type="is-default">{{reviewable.document_subtype}}</span>
                  </td>        
                  <td v-if="isAnnualReview" style='text-align:center'>{{reviewable.review_interval}}</td>            
                  <td v-if="isAnnualReview" style='text-align:center'>{{reviewable.published_at}}</td> 
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isClosed">
            <b-tabs v-model="activeTab" type='is-boxed' size='is-small'>
              <b-tab-item v-for="reviewable in review.reviewables" :label="reviewable.short_title" :icon="reviewable.review_target_set ? '' : 'exclamation-triangle'">
                <div class="columns">
                  <div class="column">
                    <label class="label">Title</label>
                    {{reviewable.title}}
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-2">
                    <label class="label">Document Type</label>
                    <span :class="documentTypeClass(reviewable.document_type)">{{reviewable.document_type}}</span>
                  </div>

                  <div class="column is-2">
                    <div v-if="showDecision">
                      <label class="label">Decision</label>
                      <b-select v-model="reviewable.review_target_state" expanded>
                        <option value=""></option>
                        <option v-for="(display, value) in targetStates(reviewable.review_interval)" :key="value" :value="value">{{display}}</option>
                      </b-select>
                    </div>
                  </div>

                  <div class="column is-5">
                    <div v-if="showRecommendation">
                      <label class="label">Recommendation</label>
                      <b-input v-model="reviewable.recommendation" placeholder="Recommendation" size="is-small" />
                      <p class='help'>Required when retiring</p>
                    </div>                     
                  </div>

                  <div class="column is-3">
                    <div v-if="showDeadline">
                      <b-field label="Author Deadline" :type="fieldType('due_at')" :message="fieldErrors('due_at')">
                        <b-datepicker v-model="reviewable.due_at"
                          placeholder="Click to select..."
                          :date-formatter="dateFormatter"
                          icon="calendar-alt">
                        </b-datepicker>
                      </b-field>
                    </div>     
                    
                    <div v-if="isAnnualReview && reviewable.review_target_state == 'revised'">
                      <label class="label">Revising Author</label>
                      <b-select v-model="reviewable.revising_author_id" expanded>
                        <option value=null></option>
                        <option v-for="user in review.revising_authors" :value="user.id">{{user.full_name}}</option>
                      </b-select>
                      <p class='help'>Required when revising</p>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div v-if="isPaper && !isAnnualReview" class="column is-half">
                    <iframe class="has-ratio is-fullwidth" :src="reviewable.draft_url"></iframe>
                  </div>

                  <div class="column">
                    <label class="label">Comments</label>
                    <p v-if="reviewable.review_comments.length == 0">No Review Comments</p>
                    <table v-else class='table is-fullwidth'>
                      <thead>
                        <tr>
                          <th>Commenter</th>
                          <th>Group</th>
                          <th style='width:50%'>Comment</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="review_comment in reviewable.review_comments">
                          <td>{{review_comment.user}}</td>
                          <td>{{review_comment.group}}</td>
                          <td><b-input v-model="review_comment.comment" size="is-small" type="textarea" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </b-field>

      <hr>
      <b-button type="is-primary" :label="saveButtonLabel" :disabled="disableSave" native-type="submit" />  
    </form>
  </div>
</template>

<script>
  import formFieldsMixin from '@/mixins/formFieldsMixin.js'

  export default {
    name: "ReviewForm",
    props: {
      id: String
    },
    mixins: [formFieldsMixin],
    mounted () {
      if(this.isEdit()) {
        this.getReview()
      } else {
        this.review = { type: this.reviewTypes[0].type, label: null, deadline_at: null }
      }    
    },
    data () {
      return {
        authoringGroupId: null,
        eligibleReviewables: [],
        reviewableIds: [],
        activeTab: 0,
        errors: {},
        reviewTypes: [
          {
            type: 'IntentReview::Board',
            slug: 'intent_review_board',
            label: 'Intent Board',
            selection: 'multiple',
            reviewable_type: 'intent',
            show_decision: true,
            show_recommendation: true,
            show_deadline: false,
            target_states: {
              writing: 'approved',
              not_approved: 'not approved'
            }
          },
          {
            type: 'PaperReview::Expert',
            slug: 'paper_review_expert',
            label: 'Paper Expert',
            selection: 'single',
            reviewable_type: 'paper',
            show_decision: false,
            show_recommendation: false,
            show_deadline: true
          },     
          {
            type: 'PaperReview::ExpertFollowup',
            slug: 'paper_review_expert_followup',
            label: 'Paper Expert Followup',
            selection: 'single',
            reviewable_type: 'paper',
            show_decision: true,
            show_recommendation: false,
            show_deadline: false,
            target_states: {
              in_staff_review: 'ready for board',
              withdrawn: 'withdrawn'
            }
          },    
          {
            type: 'PaperReview::Board',
            slug: 'paper_review_board',
            label: 'Paper Board',
            selection: 'single',
            reviewable_type: 'paper',
            show_decision: false,
            show_recommendation: false,
            show_deadline: true
          },
          {
            type: 'PaperReview::BoardFollowup',
            slug: 'paper_review_board_followup',
            label: 'Paper Board Followup',
            selection: 'single',
            reviewable_type: 'paper',
            show_decision: true,
            show_recommendation: false,
            show_deadline: false,
            target_states: {
              in_press: 'in press',
              withdrawn: 'withdrawn'
            }
          },   
          {
            type: 'PaperReview::Annual',
            slug: 'paper_review_annual',
            label: 'Annual Review',
            selection: 'multiple',
            reviewable_type: 'paper',
            show_decision: true,
            show_recommendation: true,
            show_deadline: false,
            target_states: {
              early: {
                reaffirmed: 'reaffirmed',
                revised: 'revise',
                retired: 'retire'
              },
              late: {
                revised: 'revise',
                retired: 'retire'
              }
            }
          }                                            
        ],
        review: null,
        isLoading: true
      }
    },
    watch: {
      'review.type': function () {
        if(this.isNew()) {
          this.reviewableIds = []            

          this.getEligibleReviewables().then((res) => {
            if(this.isIntent) {
              this.authoringGroupId = null
            } else {
              this.authoringGroupId = this.authoringGroups[0].id
            } 
          })
        }
      },
      'authoringGroupId': function () {
        if(this.isNew()) {
          this.reviewableIds = []    
        }        
      },
      'reviewableIds': function () {
        if(this.isNew()) {
          if(this.isSingleSelection) {
          //  this.review.label = reviewable.title
          } else {
            this.review.label = null
          }
        }
      }
    }, 
    computed: {
      disableSave () {
        return this.isNew() ? (this.eligibleReviewables.length == 0 || this.reviewableIds.length == 0) : false
      },
      saveButtonLabel () {
        return this.isEdit() ? 'Update Review' : 'Create Review'
      },
      reviewableType () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).reviewable_type
      },
      slug () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).slug
      },
      isSingleSelection () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).selection == 'single'
      },
      isMultipleSelection () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).selection == 'multiple'
      },      
      isAnnualReview () {
        return this.review.type == 'PaperReview::Annual'
      },
      isIntent () {
        return this.reviewableType == 'intent'
      },
      isPaper () {
        return this.reviewableType == 'paper'
      },      
      isNotStarted () {
        return this.review.current_state == 'not_started'
      },
      isUnderway () {
        return this.review.current_state == 'underway'
      },      
      isClosed () {
        return this.review.current_state == 'closed'
      },
      showRecommendation () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).show_recommendation
      },
      showDecision () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).show_decision
      },      
      showDeadline () {
        return this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).show_deadline
      },        
      authoringGroups () {
        let authoringGroups = {}

        this.eligibleReviewables.map((reviewable) => reviewable.authoring_groups).flat().forEach(reviewable => authoringGroups[reviewable.id] = reviewable)
    
        return Object.values(authoringGroups)
      },
      filteredEligibleReviewables () {
        return this.isAnnualReview ? this.eligibleReviewables.filter(reviewable => reviewable.authoring_groups.filter(group => group.id == this.authoringGroupId).length > 0) : this.eligibleReviewables
      }
    },  
    methods: {
      documentTypeClass (documentType) {
        var tagClass = documentType == 'statement' ? 'is-primary' : 'is-info'
        return `tag ${tagClass}`
      },
      dateFormatter (date) {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]
      },   
      targetStates (year=null) {
        var targetStates = this.reviewTypes.find((reviewType) => reviewType.type == this.review.type).target_states

        if(this.review.type == 'PaperReview::Annual') {
          if(year < 10) {
            targetStates = targetStates.early
          } else {
            targetStates = targetStates.late
          }
        }

        return targetStates
      },      
      getReview () {
        var url = `/reviews/${this.id}`

        this.$secured.get(url)
          .then(response => {
            this.review = response.data
            this.review.deadline_at = new Date(Date.parse(`${this.review.deadline_at} 00:00:00 GMT-0500`))
            this.review.reviewables.forEach((reviewable) => reviewable.due_at = new Date(Date.parse(`${reviewable.due_at} 00:00:00 GMT-0500`)))
            this.isLoading = false
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Failed to get review: ${error}`,
              position: 'is-bottom',
              type: 'is-danger'
            })    
          }) 
      },
      async getEligibleReviewables () {
        var url = `/reviews/new`

        this.isLoading = true
        await this.$secured.get(url, { params: { type: this.review.type } })
          .then(response => {
            this.eligibleReviewables = response.data
            this.isLoading = false
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Failed to get review: ${error}`,
              position: 'is-bottom',
              type: 'is-danger'
            })    
          })    
      },
      params () {
        var params = {}
        var slug = this.slug
        var isClosed = this.isClosed
        var isIntent = this.isIntent

        params[slug] = { type: this.review.type, label: this.review.label, deadline_at: this.review.deadline_at }

        if(this.isNew()) {
          params[slug].reviewable_ids = this.isSingleSelection ? [this.reviewableIds] : this.reviewableIds
        } else {
          params[slug].reviewables_attributes = this.review.reviewables.map(function(reviewable) {
            var reviewable_attributes = { id: reviewable.id }

            reviewable_attributes[`${slug}_staff_comment`] = reviewable.staff_comment
            if(isClosed) {
              var recommendationField = `${slug}_recommendation`

              if(isIntent) {
                recommendationField = 'recommendation'
              }
              reviewable_attributes[recommendationField] = reviewable.recommendation
              reviewable_attributes.review_target_state  = reviewable.review_target_state
              reviewable_attributes.revising_author_id  = reviewable.revising_author_id
              reviewable_attributes.due_at = reviewable.due_at
            }

            return reviewable_attributes
          })

          params[slug].user_review_answers_attributes = this.review.reviewables.map(function(reviewable) {
            return reviewable.review_comments.map(function(review_comment) {
              var user_review_answer_attributes = { id: review_comment.id }

              user_review_answer_attributes.comments = review_comment.comment

              return user_review_answer_attributes
            })
          }).flat()
        }

        return params
      },
      processForm () {
        if(this.isEdit()) {
          this.updateReview()
        } else {
          this.addReview()
        }
      },   
      addReview () {
        this.$secured.post('/reviews', this.params())
        .then((response) => {
          var id = response.data.id

          this.$buefy.toast.open({
            duration: 2000,
            message: `Successfully added review`,
            position: 'is-bottom',
            type: 'is-success'
          })    

          window.location.href = `/reviews/${id}`
        })
        .catch((error) => {
          if (error.response) { 
            this.errors = error.response.data.errors
          }

          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to add reviews: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })   
      },
      updateReview () {
        var url = `/reviews/${this.id}`

        this.$secured.put(url, this.params())
        .then((response) => {
          response

          this.$buefy.toast.open({
            duration: 2000,
            message: `Successfully updated review`,
            position: 'is-bottom',
            type: 'is-success'
          })    

          window.location.href = `/reviews/${this.id}`
        })
        .catch((error) => {
          if (error.response) { 
            this.errors = error.response.data.errors
          }

          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to update review: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })   
      }                
    }
  }
</script>